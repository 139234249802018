import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as juridicalPersonService from "../../services/JuridicalPersonService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";
import FilterTableRow from "./../../components/tableFilterRow/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [juridicalPersons, setJuridicalPersons] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ common: null });
  const [isOpenFilterCombo, setIsOpenFilterCombo] = useState(false);
  const [isFirstRendered, setIsFirstRendered] = useState(true);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getJuridicalPersons(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  useEffect(() => {
    if (isFirstRendered) {
      setIsFirstRendered(false);
    } else {
      if (state?.juridicalFilter != null) {
        const timer = setTimeout(() => {
          getJuridicalPersons(PAGE_INDEX, localStorage.rowCount);
        }, 1500);

        return () => clearTimeout(timer);
      }
    }
  }, [state?.juridicalFilter]);

  const getJuridicalPersons = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await juridicalPersonService.getJuridicalPersonsByBranch(pageIndex, pageSize, localStorage.branch, state?.juridicalFilter);
    if (result.success) {
      setJuridicalPersons(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteJuridicalPerson = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await juridicalPersonService.deleteJuridicalPerson(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getJuridicalPersons(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const Search = (e) => {
    e.preventDefault();
    getJuridicalPersons(PAGE_INDEX, localStorage.rowCount);
  };

  const changeActive = async (idJuridicalPerson) => {
    let result = await juridicalPersonService.changeActive(idJuridicalPerson);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getJuridicalPersons(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getJuridicalPersons(pageIndex, localStorage.rowCount);
  };

  const AddFilter = (filterName) => {
    setFilter({ common: filter.common, [filterName]: true });
    // getStudents(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons deletePath={`juridical/delete`} editPath={`juridical/edit`} addPath={`juridical/add`} deleteRow={deleteJuridicalPerson} selectedRow={selectedRow} />

      <label className="f-right">
        <div className={`dropdown ${isOpenFilterCombo && "open"}`}>
          <button onClick={() => setIsOpenFilterCombo(!isOpenFilterCombo)} className="dropdown-toggle btn open-blue" data-toggle="dropdown">
            Filter <b className="caret" />
          </button>
          <ul className="dropdown-menu dropdown-menu-left">
            <li>
              <a onClick={() => AddFilter("isAll")}>Hamısı</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isActiveContract")}>Aktiv müqaviləsi olan hüquqi şəxslər</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isNotActiveContract")}>Aktiv müqaviləsi olmayan hüquqi şəxslər</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isOneMoreActiveContract")}>Birdən artıq aktiv müqaviləsi olan hüquqi şəxslər</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isNotContract")}>Müqavilə bağlanmamış hüquqi şəxslər</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isContract")}>Müqavilə bağlanmış hüquqi şəxslər</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isOneMoreContract")}>Birdən artıq müqavilə bağlanmış hüquqi şəxslər</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isStoppedContract")}>Dayandırılmış müqaviləsi olan hüquqi şəxslər</a>
            </li>
          </ul>
        </div>
      </label>

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assignment_ind</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Hüquqi şəxslərin siyahısı</h4>

          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getJuridicalPersons(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                <input type="text" className="form-control" placeholder=" Axtar " value={state?.juridicalFilter?.common} onChange={(e) => setState({ ...state, juridicalFilter: { ...state?.juridicalFilter, common: e.target.value } })} />
                <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>VÖEN</th>
                    <th>Adı</th>
                    <th>Əlaqəli şəxs</th>
                    <th>Fəaliyyət sahəsi</th>
                    <th>Aktivlik</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <FilterTableRow page={"juridicalFilter"} />
                  {juridicalPersons?.length > 0 ? (
                    juridicalPersons.map((item, i) => (
                      <tr key={item?.idJuridicalPerson} onClick={() => isSelectRow(item?.idJuridicalPerson)} className={selectedRow === item?.idJuridicalPerson ? "selectedRow" : ""}>
                        <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                        <td>{item?.id}</td>
                        <td>{item?.name}</td>
                        <td>{item?.relatedPerson}</td>
                        <td>{item?.activitieType}</td>
                        <td>
                          <div className="togglebutton">
                            <label>
                              <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idJuridicalPerson)} />
                              <span className="toggle" />
                            </label>
                          </div>
                        </td>
                        <td className="td-actions text-right">
                          <Link to={`${alias}/juridical/view/${item?.idJuridicalPerson}`}>
                            <button className="btn btn-info">
                              <i className="material-icons">visibility</i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6}>
                      <NotFound />
                    </td>
                  )}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
