import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/MainContext";
import * as Alerts from "../../../../utils/Alerts";
import FormInput from "../../../../components/formInput/Index";
import FormCheckbox from "../../../../components/formCheckbox/Index";
import { useOrganization } from "../../../../helper/useOrganization";
import * as contractService from "../../../../services/ContractService";
import * as trainingTypeService from "../../../../services/TrainingTypeService";
import * as helperService from "../../../../services/HelperService";
import CommitButton from "../../../../components/commitButton/Index";
import Select from "./../../../../components/select/Index";
import StudentSelectModal from "./../../../student/modal/SelectModal";
import EmployeeSelectModal from "./../../../employee/modal/SelectModal";
import * as date from "./../../../../utils/DateNow";
import * as attachmentService from "./../../../../services/AttachmentService";
import { useTranslation } from "react-i18next";
import { useStudent } from "../../../../helper/useStudent";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [contractStudent, setContractStudent] = useState({ contractDate: date.Now(), startDate: date.Now(), endDate: date.Now(), fkIdContractStatus: 1, initialPayment: "0", initialDebt: "0" });
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [contractStatuses, setContractStatuses] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isOpenStudentSelectModal, setIsOpenStudentSelectModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [attachs, setAttachs] = useState([]);
  const [activeTab, setActiveTab] = useState("general");
  const [calculateDateTypes, setCalculateDateTypes] = useState([]);
  const [selectedCalculateDateType, setSelectedCalculateDateType] = useState({});
  const [educationForms, setEducationForms] = useState([]);

  const alias = useOrganization();
  const id = useStudent();
  const location = useLocation();
  const navigate = useNavigate();
  const { idContractStudent } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getContractStatuses();
    getCalculateDateTypes();
    getTrainingTypes();
    getEducationForms();
    idContractStudent && getContractStudent();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  useEffect(() => {    
    setSelectedCalculateDateType(calculateDateTypes.find((x) => x.idContractCalculationDateType == contractStudent?.fkIdContractCalculationDateType));
  }, [contractStudent]);

  const getContractStudent = async () => {
    let result = await contractService.getContractStudent(idContractStudent);
    if (result.success) {
      setContractStudent(result.data);
      result?.data?.student && setSelectedStudent({ rowId: result?.data?.student?.idStudent, fullName: result?.data?.student?.fullName });
      result?.data?.coordinator && setSelectedEmployee({ rowId: result?.data?.coordinator?.idEmployee, fullName: result?.data?.coordinator?.fullName });
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteExsistAttachment = async (idAttachment) => {
    let result = await attachmentService.deleteAttachment(idAttachment);
    if (result.success) {
      var exsistAttach = contractStudent.attachments.filter((x) => x.idAttachment != idAttachment);
      setContractStudent({ ...contractStudent, attachments: exsistAttach });
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateContractStudent = async () => {
    contractStudent.fkIdBranch = localStorage.branch;
    contractStudent.fkIdStudent = selectedStudent?.rowId;
    contractStudent.fkIdCoordinator = selectedEmployee?.rowId;
    let result = idContractStudent ? await contractService.updateContractStudent(contractStudent) : await contractService.addContractStudent(contractStudent);
    if (result.success) {
      const formData = new FormData();
      formData.append("fkIdAttachmentSourceType", 9);
      formData.append("fkIdAttachmentSoruce", result.data?.idContractStudent);
      attachs.forEach((attach) => {
        formData.append("files", attach);
      });
      await attachmentService.addAttachments(formData, "contractStudents");

      Alerts.successWithBack(result.message, `${alias}/contract/student`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const getTrainingTypes = async () => {
    let result = await trainingTypeService.getTrainingTypes();
    if (result.success) {
      setTrainingTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getEducationForms = async () => {
    let result = await helperService.getEducationForms();
    if (result.success) {
      setEducationForms(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getContractStatuses = async () => {
    let result = await helperService.getContractStatuses();
    if (result.success) {
      setContractStatuses(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getCalculateDateTypes = async () => {
    let result = await helperService.getContractCalculationDateTypes();
    if (result.success) {
      setCalculateDateTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setContractStudent({ ...contractStudent, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setContractStudent({ ...contractStudent, [name]: e.value });
  };

  const handleStudentSelectModal = () => {
    setIsOpenStudentSelectModal(!isOpenStudentSelectModal);
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setAttachs([...attachs, event.target.files[0]]);
    }
  };

  const handleFocus = () => {
    // Remove the '0' when the user starts typing (on focus)
    if (contractStudent?.initialPayment == "0") {
      setContractStudent({ ...contractStudent, initialPayment: "" });
    }
  };

  const handleBlur = () => {
    // If the input is empty when the user focuses out (on blur), reset to '0'
    if (contractStudent?.initialPayment == "" || contractStudent?.initialPayment == null) {
      setContractStudent({ ...contractStudent, initialPayment: "0" });
    }
  };

  const handleFocusDebt = () => {
    if (contractStudent?.initialDebt == "0") {
      setContractStudent({ ...contractStudent, initialDebt: "" });
    }
  };

  const handleBlurDebt = () => {
    // If the input is empty when the user focuses out (on blur), reset to '0'
    if (contractStudent?.initialDebt == "" || contractStudent?.initialDebt == null) {
      setContractStudent({ ...contractStudent, initialDebt: "0" });
    }
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">receipt_long</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idContractStudent ? (isView ? t("contract.infoContract") : t("contract.editContract")) : t("contract.newContract")}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ÜMUMİ MƏLUMATLAR
                    </a>
                  </li>
                  <li className={activeTab == "condition" ? "active" : ""} onClick={() => setActiveTab("condition")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ŞƏRTLƏR
                    </a>
                  </li>
                  <li className={activeTab == "file" ? "active" : ""} onClick={() => setActiveTab("file")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      QOŞMA FAYLLAR
                    </a>
                  </li>
                  <li className={activeTab == "status" ? "active" : ""} onClick={() => setActiveTab("status")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      STATUS
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <div className="row">
                      <>
                        <FormInput style={isView ? "col-sm-6" : "col-sm-7"} label={t("contract.studentFullname")} required={true} value={selectedStudent != null ? selectedStudent.fullName : ""} disabled={true} />
                        {!isView && (
                          <div className="form-group col-sm-5">
                            <label className="label-control w-100"></label>
                            {selectedStudent != null ? (
                              <button className="btn btn-danger" disabled={isView} onClick={() => setSelectedStudent(null)}>
                                <span class="material-icons">autorenew</span>
                              </button>
                            ) : null}
                            <button className="btn btn-info" disabled={isView} onClick={handleStudentSelectModal}>
                              {t("button.selectStudent")}
                            </button>
                          </div>
                        )}
                      </>
                    </div>
                    <div className="row">
                      <>
                        <FormInput style={isView ? "col-sm-6" : "col-sm-7"} label={t("contract.coordinatorFullname")} value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />
                        {!isView && (
                          <div className="form-group col-sm-5">
                            <label className="label-control w-100"></label>
                            {selectedEmployee != null ? (
                              <button className="btn btn-danger" onClick={() => setSelectedEmployee(null)}>
                                <span class="material-icons">autorenew</span>
                              </button>
                            ) : null}
                            <button className="btn btn-info" onClick={handleEmployeeSelectModal}>
                              {t("button.selectCoordinator")}
                            </button>
                          </div>
                        )}
                      </>

                      <FormInput style={"col-sm-4"} label={t("contract.contractNumber")} name={"contractNo"} value={contractStudent?.contractNo} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={t("contract.contractDate")} name={"contractDate"} type={"date"} required={true} value={contractStudent?.contractDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-4"}
                        label={t("contract.trainingType")}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdTrainingType");
                        }}
                        selectedValue={contractStudent?.fkIdTrainingType}
                        isDisabled={isView}
                        options={trainingTypes.map((item) => ({ label: item.description, value: item.idTrainingType }))}
                      />
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-4"}
                        label={"Təhsilalma forması"}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdEducationForm");
                        }}
                        selectedValue={contractStudent?.fkIdEducationForm}
                        isDisabled={isView}
                        options={educationForms.map((item) => ({ label: item.description, value: item.idEducationForm }))}
                      />
                      <FormInput style={"col-sm-4"} label={t("contract.contractAmount")} name={"contractAmount"} type={"number"} required={true} value={contractStudent?.contractAmount} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={t("contract.discount")} name={"discountAmount"} type={"number"} value={contractStudent?.discountAmount} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={t("contract.startDate")} name={"startDate"} type={"date"} required={true} value={contractStudent?.startDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={t("contract.endDate")} name={"endDate"} type={"date"} required={true} value={contractStudent?.endDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={t("contract.note")} name={"note"} value={contractStudent?.note} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "condition" ? "active" : "")}>
                    <div className="row">
                      <FormCheckbox label={"Hesablaşmaların aparılması üçün davamiyyət məlumatları əsas götürülsün"} style={"col-sm-12"} name={"condition01"} checked={contractStudent?.condition01} onChange={() => setContractStudent({ ...contractStudent, condition01: contractStudent?.condition01 == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Müqavilənin dayandırılmasından aslı olmayaraq tələbə üçün borc hesablanır"} style={"col-sm-12"} name={"condition02"} checked={contractStudent?.condition02} onChange={() => setContractStudent({ ...contractStudent, condition02: contractStudent?.condition02 == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Müqaviləyə xitam verildikdə iştirak edilməyən təlimlərin ödənişi tələbəyə geri ödənilir"} style={"col-sm-12"} name={"condition03"} checked={contractStudent?.condition03} onChange={() => setContractStudent({ ...contractStudent, condition03: contractStudent?.condition03 == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Tələbə təlimlərdə iştirak etmədiyi halda bizim tələbəyə təlim borcumuz yaranır"} style={"col-sm-12"} name={"condition04"} checked={contractStudent?.condition04} onChange={() => setContractStudent({ ...contractStudent, condition04: contractStudent?.condition04 == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Tələbə təlimlərdə təyin edilmiş saydan artıq iştirak etmədiyi halda bizim tələbəyə təlim borcumuz yaranır"} style={"col-sm-12"} name={"condition05"} checked={contractStudent?.condition05} onChange={() => setContractStudent({ ...contractStudent, condition05: contractStudent?.condition05 == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Təlimlər tamamlandıqda tələbə sertifikat ilə təmin edilir"} style={"col-sm-12"} name={"condition06"} checked={contractStudent?.condition06} onChange={() => setContractStudent({ ...contractStudent, condition06: contractStudent?.condition06 == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Fərdi təlim"} style={"col-sm-12"} name={"condition07"} checked={contractStudent?.condition07} onChange={() => setContractStudent({ ...contractStudent, condition07: contractStudent?.condition07 == 1 ? 0 : 1 })} disabled={isView} />
                      <br />
                      <FormInput style={"col-sm-6"} type={"number"} label={"Müqavilə üzrə ilkin ödənilməli məbləğ"} name={"initialDebt"} value={contractStudent?.initialDebt + ""} onChange={handleInput} onFocus={handleFocusDebt} onBlur={handleBlurDebt} disabled={isView} />
                      <FormInput style={"col-sm-6"} type={"number"} label={"Müqavilə üzrə ilkin ödənilən məbləğ"} name={"initialPayment"} value={contractStudent?.initialPayment + ""} onChange={handleInput} onFocus={handleFocus} onBlur={handleBlur} disabled={isView} />
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-7"}
                        label={"Hesablanma tarixi"}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdContractCalculationDateType");
                          setSelectedCalculateDateType(calculateDateTypes.find((x) => x.idContractCalculationDateType == e.value));
                        }}
                        selectedValue={contractStudent?.fkIdContractCalculationDateType}
                        isDisabled={isView}
                        options={calculateDateTypes.map((item) => ({ label: item.description, value: item.idContractCalculationDateType }))}
                      />
                      {selectedCalculateDateType?.requestParameter == 1 ? <FormInput style={"col-sm-5"} type={"number"} label={selectedCalculateDateType?.parameterTitle} name={"calculationDateValue"} value={contractStudent?.calculationDateValue} onChange={handleInput} disabled={isView} /> : null}
                    </div>
                    <br /> <p className="color-red">{selectedCalculateDateType?.note}</p>
                  </div>
                </div>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "file" ? "active" : "")}>
                    {isView ? null : (
                      <div className="row">
                        <span className="btn-file pl-15">
                          <button type="submit" className="btn btn-fill btn-info ">
                            {t("file.select")}
                          </button>
                          <input type="file" onChange={onImageChange} accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.tif,.xls,.xlsx" />
                        </span>
                      </div>
                    )}
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>{t("file.name")}</th>
                            <th>{t("file.type")}</th>
                            <th>{t("file.size")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {contractStudent?.attachments?.map((item, i) => (
                            <tr key={i}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item?.originalFileName}</td>
                              <td>{item?.fileType}</td>
                              <td></td>
                              <td className="td-actions text-right">
                                {isView ? null : (
                                  <button className="btn btn-danger" onClick={() => deleteExsistAttachment(item?.idAttachment)}>
                                    <i className="material-icons">delete</i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}

                          {attachs.map((item, i) => (
                            <tr key={i}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item?.name}</td>
                              <td>{item?.type}</td>
                              <td>{(item?.size / (1024 * 1024)).toFixed(2)} Mb</td>
                              <td className="td-actions text-right">
                                {isView ? null : (
                                  <button className="btn btn-danger" onClick={() => setAttachs(attachs.filter((x) => x != item))}>
                                    <i className="material-icons">delete</i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "status" ? "active" : "")}>
                    <div className="row">
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-4"}
                        label={t("contract.contractStatus")}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdContractStatus");
                        }}
                        selectedValue={contractStudent?.fkIdContractStatus}
                        isDisabled={isView}
                        options={contractStatuses.map((item) => ({ label: item.description, value: item.idContractStatus }))}
                      />
                      <FormInput style={"col-sm-8"} label={t("contract.contractStatusNote")} name={"contractStatusNote"} value={contractStudent?.contractStatusNote} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>
                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateContractStudent} text={t("button.accept")} />}
                  <Link to={`${alias}${id}/contract/student`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      {t("button.cancel")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenStudentSelectModal ? <StudentSelectModal isOpen={isOpenStudentSelectModal} handleStudentSelectModal={handleStudentSelectModal} setSelectedStudent={setSelectedStudent} /> : null}
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} /> : null}
    </div>
  );
}
