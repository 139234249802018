import React from "react";
import * as util from "../../utils/Util";

export default function TrTrainingTypeReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.description}</td>
      <td>{item?.group_count}</td>
      <td>{item?.contract_count}</td>
      <td>{util.FixedNumber(item?.contract_sum)}</td>
      <td>{util.FixedNumber(item?.contract_debt_sum)}</td>
      <td>{util.FixedNumber(item?.payment_sum)}</td>
    </tr>
  ) : null;
}


