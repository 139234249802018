import React from "react";
import { useMainContext } from "../../contexts/MainContext";

export default function Index({ page, isAccessDetail }) {
  const { state, setState } = useMainContext();

  const addFilter = (name, value) => {
    setState({ ...state, [page]: { ...state[page], [name]: value } });
  };

  return page == "studentFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fullName} onChange={(e) => addFilter("fullName", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fin} onChange={(e) => addFilter("fin", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.age} onChange={(e) => addFilter("age", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      {isAccessDetail && (
        <td>
          <div class="input-wrapper">
            <input type="text" value={state?.[page]?.phone} onChange={(e) => addFilter("phone", e.target.value)} />
            <i className="material-icons material-icons-input">search</i>
          </div>
        </td>
      )}
      <td></td>
      <td></td>
    </tr>
  ) : page == "trainerFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fullName} onChange={(e) => addFilter("fullName", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.phone} onChange={(e) => addFilter("phone", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.email} onChange={(e) => addFilter("email", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td></td>
    </tr>
  ) : page == "employeeFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fullName} onChange={(e) => addFilter("fullName", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.phone} onChange={(e) => addFilter("phone", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.email} onChange={(e) => addFilter("email", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.post} onChange={(e) => addFilter("post", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td></td>
      <td></td>
    </tr>
  ) : page == "juridicalFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.voen} onChange={(e) => addFilter("voen", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.name} onChange={(e) => addFilter("name", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.relatedPerson} onChange={(e) => addFilter("relatedPerson", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.activitieType} onChange={(e) => addFilter("activitieType", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td></td>
      <td></td>
    </tr>
  ) : page == "contractStudentFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fullName} onChange={(e) => addFilter("fullName", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.contractDate} onChange={(e) => addFilter("contractDate", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.trainingType} onChange={(e) => addFilter("trainingType", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.contractAmount} onChange={(e) => addFilter("contractAmount", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.discountAmount} onChange={(e) => addFilter("discountAmount", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.contractNo} onChange={(e) => addFilter("contractNo", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.contractStatus} onChange={(e) => addFilter("contractStatus", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td></td>
    </tr>
  ) : null;
}
