import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/MainContext";
import * as Alerts from "../../../../utils/Alerts";
import FormInput from "../../../../components/formInput/Index";
import FormCheckbox from "../../../../components/formCheckbox/Index";
import { useOrganization } from "../../../../helper/useOrganization";
import * as contractService from "../../../../services/ContractService";
import * as trainingTypeService from "../../../../services/TrainingTypeService";
import * as helperService from "../../../../services/HelperService";
import CommitButton from "../../../../components/commitButton/Index";
import Select from "./../../../../components/select/Index";
import TrainerSelectModal from "./../../../employee/modal/SelectModal";
import EmployeeSelectModal from "./../../../employee/modal/SelectModal";
import * as date from "./../../../../utils/DateNow";
import * as attachmentService from "./../../../../services/AttachmentService";
import { useTranslation } from "react-i18next";
import { useStudent } from "../../../../helper/useStudent";

export default function AddModal({ isOpen, handleViewModal, header, trainerId }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [contractTrainer, setContractTrainer] = useState({ contractDate: date.Now(), startDate: date.Now(), endDate: date.Now(), fkIdContractStatus: 1 });
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [contractStatuses, setContractStatuses] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [isOpenTrainerSelectModal, setIsOpenTrainerSelectModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [attachs, setAttachs] = useState([]);
  const [activeTab, setActiveTab] = useState("general");
  const [calculateDateTypes, setCalculateDateTypes] = useState([]);
  const [selectedCalculateDateType, setSelectedCalculateDateType] = useState({});

  const alias = useOrganization();
  const id = useStudent();
  const location = useLocation();
  const navigate = useNavigate();
  const { idContractTrainer } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getContractStatuses();
    getCalculateDateTypes();
    getTrainingTypes();
    idContractTrainer && getContractTrainer();
    //idContractTrainer && setSelectedCalculateDateType(calculateDateTypes.find((x) => x.idContractCalculationDateType == contractTrainer?.fkIdContractCalculationDateType));
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

      useEffect(() => {    
        setSelectedCalculateDateType(calculateDateTypes.find((x) => x.idContractCalculationDateType == contractTrainer?.fkIdContractCalculationDateType));
      }, [contractTrainer]);

  const getContractTrainer = async () => {
    let result = await contractService.getContractTrainer(idContractTrainer);
    if (result.success) {
      setContractTrainer(result.data);
      result?.data?.trainer && setSelectedTrainer({ rowId: result?.data?.trainer?.idEmployee, fullName: result?.data?.trainer?.fullName });
      result?.data?.coordinator && setSelectedEmployee({ rowId: result?.data?.coordinator?.idEmployee, fullName: result?.data?.coordinator?.fullName });
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteExsistAttachment = async (idAttachment) => {
    let result = await attachmentService.deleteAttachment(idAttachment);
    if (result.success) {
      var exsistAttach = contractTrainer.attachments.filter((x) => x.idAttachment != idAttachment);
      setContractTrainer({ ...contractTrainer, attachments: exsistAttach });
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateContractTrainer = async () => {
    contractTrainer.fkIdBranch = localStorage.branch;
    contractTrainer.fkIdTrainer = trainerId;
    contractTrainer.fkIdCoordinator = selectedEmployee?.rowId;
    let result = idContractTrainer ? await contractService.updateContractTrainer(contractTrainer) : await contractService.addContractTrainer(contractTrainer);
    if (result.success) {
      const formData = new FormData();
      formData.append("fkIdAttachmentSourceType", 11);
      formData.append("fkIdAttachmentSoruce", result.data?.idContractTrainer);
      attachs.forEach((attach) => {
        formData.append("files", attach);
      });
      await attachmentService.addAttachments(formData, "contractTrainers");

      handleViewModal();

    //  Alerts.successWithBack(result.message, `${alias}/contract/trainer`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const getTrainingTypes = async () => {
    let result = await trainingTypeService.getTrainingTypes();
    if (result.success) {
      setTrainingTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getContractStatuses = async () => {
    let result = await helperService.getContractStatuses();
    if (result.success) {
      setContractStatuses(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getCalculateDateTypes = async () => {
    let result = await helperService.getContractCalculationDateTypes();
    if (result.success) {
      setCalculateDateTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setContractTrainer({ ...contractTrainer, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setContractTrainer({ ...contractTrainer, [name]: e.value });
  };

  const handleTrainerSelectModal = () => {
    setIsOpenTrainerSelectModal(!isOpenTrainerSelectModal);
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setAttachs([...attachs, event.target.files[0]]);
    }
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="listGroupMemberModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }} id="listGroupMemberModalLabel">
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <h4 className="card-title">{idContractTrainer ? (isView ? t("contract.infoContract") : t("contract.editContract")) : t("contract.newContract")}</h4>

                  <ul className="nav nav-pills nav-pills-warning">
                    <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                      <a href="javascript:void(0)" data-toggle="tab">
                        ÜMUMİ MƏLUMATLAR
                      </a>
                    </li>
                    <li className={activeTab == "condition" ? "active" : ""} onClick={() => setActiveTab("condition")}>
                      <a href="javascript:void(0)" data-toggle="tab">
                        ŞƏRTLƏR
                      </a>
                    </li>
                    <li className={activeTab == "file" ? "active" : ""} onClick={() => setActiveTab("file")}>
                      <a href="javascript:void(0)" data-toggle="tab">
                        QOŞMA FAYLLAR
                      </a>
                    </li>
                    <li className={activeTab == "status" ? "active" : ""} onClick={() => setActiveTab("status")}>
                      <a href="javascript:void(0)" data-toggle="tab">
                        STATUS
                      </a>
                    </li>
                    {/* <li className={activeTab == "calculateRule" ? "active" : ""} onClick={() => setActiveTab("calculateRule")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      HESABLAMA QAYDASI
                    </a>
                  </li> */}
                  </ul>

                  <div className="tab-content">
                    <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                  
                      <div className="row">
                        <>
                          <FormInput style={isView ? "col-sm-6" : "col-sm-7"} label={t("contract.coordinatorFullname")} value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />
                          {!isView && (
                            <div className="form-group col-sm-5">
                              <label className="label-control w-100"></label>
                              {selectedEmployee != null ? (
                                <button className="btn btn-danger" onClick={() => setSelectedEmployee(null)}>
                                  <span class="material-icons">autorenew</span>
                                </button>
                              ) : null}
                              <button className="btn btn-info" onClick={handleEmployeeSelectModal}>
                                {t("button.selectCoordinator")}
                              </button>
                            </div>
                          )}
                        </>

                        <FormInput style={"col-sm-4"} label={t("contract.contractNumber")} name={"contractNo"} value={contractTrainer?.contractNo} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={t("contract.contractDate")} required={true} name={"contractDate"} type={"date"} value={contractTrainer?.contractDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                        <Select
                          placeholder={"Seç"}
                          style={"col-sm-4"}
                          label={t("contract.trainingType")}
                          changeSelectHandler={(e) => {
                            handleSelect(e, "fkIdTrainingType");
                          }}
                          selectedValue={contractTrainer?.fkIdTrainingType}
                          isDisabled={isView}
                          options={trainingTypes.map((item) => ({ label: item.description, value: item.idTrainingType }))}
                        />
                        {/* <FormInput style={"col-sm-4"} label={t("contract.contractAmount")} name={"contractAmount"} value={contractTrainer?.contractAmount} onChange={handleInput} disabled={isView} /> */}
                        {/* <FormInput style={"col-sm-4"} label={t("contract.discount")} name={"discountAmount"} value={contractTrainer?.discountAmount} onChange={handleInput} disabled={isView} /> */}
                        <FormInput style={"col-sm-4"} label={t("contract.startDate")} required={true} name={"startDate"} type={"date"} value={contractTrainer?.startDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={t("contract.endDate")} required={true} name={"endDate"} type={"date"} value={contractTrainer?.endDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={t("contract.note")} name={"note"} value={contractTrainer?.note} onChange={handleInput} disabled={isView} />
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div className={"tab-pane " + (activeTab === "condition" ? "active" : "")}>
                      <div className="row">
                        <Select
                          placeholder={"Seç"}
                          style={"col-sm-6"}
                          label={"Hesablanma tarixi"}
                          changeSelectHandler={(e) => {
                            handleSelect(e, "fkIdContractCalculationDateType");
                            setSelectedCalculateDateType(calculateDateTypes.find((x) => x.idContractCalculationDateType == e.value));
                          }}
                          selectedValue={contractTrainer?.fkIdContractCalculationDateType}
                          isDisabled={isView}
                          options={calculateDateTypes.map((item) => ({ label: item.description, value: item.idContractCalculationDateType }))}
                        />
                        {selectedCalculateDateType?.requestParameter == 1 ? <FormInput style={"col-sm-6"} type={"number"} label={selectedCalculateDateType?.parameterTitle} name={"calculationDateValue"} value={contractTrainer?.calculationDateValue} onChange={handleInput} disabled={isView} /> : null}
                      </div>
                      <br /> <p className="color-red">{selectedCalculateDateType?.note}</p>
                    </div> 
                  </div>

                  <div className="tab-content">
                    <div className={"tab-pane " + (activeTab === "file" ? "active" : "")}>
                      {isView ? null : (
                        <div className="row">
                          <span className="btn-file pl-15">
                            <button type="submit" className="btn btn-fill btn-info ">
                              {t("file.select")}
                            </button>
                            <input type="file" onChange={onImageChange} accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.tif,.xls,.xlsx" />
                          </span>
                        </div>
                      )}
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>{t("file.name")}</th>
                              <th>{t("file.type")}</th>
                              <th>{t("file.size")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {contractTrainer?.attachments?.map((item, i) => (
                              <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item?.originalFileName}</td>
                                <td>{item?.fileType}</td>
                                <td></td>
                                <td className="td-actions text-right">
                                  {isView ? null : (
                                    <button className="btn btn-danger" onClick={() => deleteExsistAttachment(item?.idAttachment)}>
                                      <i className="material-icons">delete</i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {attachs.map((item, i) => (
                              <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.type}</td>
                                <td>{(item?.size / (1024 * 1024)).toFixed(2)} Mb</td>
                                <td className="td-actions text-right">
                                  {isView ? null : (
                                    <button className="btn btn-danger" onClick={() => setAttachs(attachs.filter((x) => x != item))}>
                                      <i className="material-icons">delete</i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div className={"tab-pane " + (activeTab === "status" ? "active" : "")}>
                      <div className="row">
                        <Select
                          placeholder={"Seç"}
                          style={"col-sm-4"}
                          label={t("contract.contractStatus")}
                          changeSelectHandler={(e) => {
                            handleSelect(e, "fkIdContractStatus");
                          }}
                          selectedValue={contractTrainer?.fkIdContractStatus}
                          isDisabled={isView}
                          options={contractStatuses.map((item) => ({ label: item.description, value: item.idContractStatus }))}
                        />
                        <FormInput style={"col-sm-8"} label={t("contract.contractStatusNote")} name={"contractStatusNote"} value={contractTrainer?.contractStatusNote} onChange={handleInput} disabled={isView} />
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div className={"tab-pane " + (activeTab === "calculateRule" ? "active" : "")}>
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={addOrUpdateContractTrainer} text={t("button.accept")} />}

            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {/* {isOpenStudentSelectModal ? <StudentSelectModal isOpen={isOpenStudentSelectModal} handleStudentSelectModal={handleStudentSelectModal} setSelectedStudent={setSelectedStudent} /> : null} */}
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} /> : null}
    </div>
  );
}
