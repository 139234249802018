import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/MainContext";
import * as Alerts from "../../../../utils/Alerts";
import FormInput from "../../../../components/formInput/Index";
import FormCheckbox from "../../../../components/formCheckbox/Index";
import { useOrganization } from "../../../../helper/useOrganization";
import * as contractService from "../../../../services/ContractService";
import * as trainingTypeService from "../../../../services/TrainingTypeService";
import * as helperService from "../../../../services/HelperService";
import CommitButton from "../../../../components/commitButton/Index";
import Select from "./../../../../components/select/Index";
import JuridicalPersonSelectModal from "./../../../juridicalPerson/modal/SelectModal";
import EmployeeSelectModal from "./../../../employee/modal/SelectModal";
import * as date from "./../../../../utils/DateNow";
import * as attachmentService from "./../../../../services/AttachmentService";
import { useTranslation } from "react-i18next";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [contractJuridical, setContractJuridical] = useState({ contractDate: date.Now(), startDate: date.Now(), endDate: date.Now(), fkIdContractStatus: 1 });
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [contractStatuses, setContractStatuses] = useState([]);
  const [selectedJuridical, setSelectedJuridical] = useState(null);
  const [isOpenJuridicalPersonSelectModal, setIsOpenJuridicalPersonSelectModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [attachs, setAttachs] = useState([]);
  const [activeTab, setActiveTab] = useState("general");
  const [calculateDateTypes, setCalculateDateTypes] = useState([]);
  const [selectedCalculateDateType, setSelectedCalculateDateType] = useState({});

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idContractJuridical } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getContractStatuses();
    getCalculateDateTypes();
    getTrainingTypes();
    idContractJuridical && getContractJuridical();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

    useEffect(() => {    
      setSelectedCalculateDateType(calculateDateTypes.find((x) => x.idContractCalculationDateType == contractJuridical?.fkIdContractCalculationDateType));
    }, [contractJuridical]);

  const getContractJuridical = async () => {
    let result = await contractService.getContractJuridical(idContractJuridical);
    if (result.success) {
      setContractJuridical(result.data);
      result?.data?.juridicalPerson && setSelectedJuridical({ rowId: result?.data?.juridicalPerson?.idJuridicalPerson, fullName: result?.data?.juridicalPerson?.name });
      result?.data?.coordinator && setSelectedEmployee({ rowId: result?.data?.coordinator?.idEmployee, fullName: result?.data?.coordinator?.fullName });
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteExsistAttachment = async (idAttachment) => {
    let result = await attachmentService.deleteAttachment(idAttachment);
    if (result.success) {
      var exsistAttach = contractJuridical.attachments.filter((x) => x.idAttachment != idAttachment);
      setContractJuridical({ ...contractJuridical, attachments: exsistAttach });
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateContractJuridical = async () => {
    contractJuridical.fkIdBranch = localStorage.branch;
    contractJuridical.fkIdJuridicalPerson = selectedJuridical?.rowId;
    contractJuridical.fkIdCoordinator = selectedEmployee?.rowId;
    let result = idContractJuridical ? await contractService.updateContractJuridical(contractJuridical) : await contractService.addContractJuridical(contractJuridical);
    if (result.success) {
      const formData = new FormData();
      formData.append("fkIdAttachmentSourceType", 10);
      formData.append("fkIdAttachmentSoruce", result.data?.idContractJuridicalPerson);
      attachs.forEach((attach) => {
        formData.append("files", attach);
      });
      await attachmentService.addAttachments(formData, "contractJuridicals");

      Alerts.successWithBack(result.message, `${alias}/contract/juridical`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const getTrainingTypes = async () => {
    let result = await trainingTypeService.getTrainingTypes();
    if (result.success) {
      setTrainingTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getContractStatuses = async () => {
    let result = await helperService.getContractStatuses();
    if (result.success) {
      setContractStatuses(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getCalculateDateTypes = async () => {
    let result = await helperService.getContractCalculationDateTypes();
    if (result.success) {
      setCalculateDateTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setContractJuridical({ ...contractJuridical, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setContractJuridical({ ...contractJuridical, [name]: e.value });
  };

  const handleJuridicalPersonSelectModal = () => {
    setIsOpenJuridicalPersonSelectModal(!isOpenJuridicalPersonSelectModal);
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setAttachs([...attachs, event.target.files[0]]);
    }
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">receipt_long</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idContractJuridical ? (isView ? "Müqavilə məlumatları" : "Müqavilə məlumatlarının redaktəsi") : "Yeni müqavilə"}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ÜMUMİ MƏLUMATLAR
                    </a>
                  </li>
                  <li className={activeTab == "condition" ? "active" : ""} onClick={() => setActiveTab("condition")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ŞƏRTLƏR
                    </a>
                  </li>
                  <li className={activeTab == "file" ? "active" : ""} onClick={() => setActiveTab("file")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      QOŞMA FAYLLAR
                    </a>
                  </li>
                  <li className={activeTab == "status" ? "active" : ""} onClick={() => setActiveTab("status")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      STATUS
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <div className="row">
                      <>
                        <FormInput style={isView ? "col-sm-6" : "col-sm-7"} required={true} label={"Hüquqi şəxsin tam adı"} value={selectedJuridical != null ? selectedJuridical.fullName : ""} disabled={true} />
                        {!isView && (
                          <div className="form-group col-sm-5">
                            <label className="label-control w-100"></label>
                            {selectedJuridical != null ? (
                              <button className="btn btn-danger" disabled={isView} onClick={() => setSelectedJuridical(null)}>
                                <span class="material-icons">autorenew</span>
                              </button>
                            ) : null}
                            <button className="btn btn-info" disabled={isView} onClick={handleJuridicalPersonSelectModal}>
                              Hüquqi şəxs seç
                            </button>
                          </div>
                        )}
                      </>
                    </div>
                    <div className="row">
                      <>
                        <FormInput style={isView ? "col-sm-6" : "col-sm-7"} label={"Koordinatorun tam adı"} value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />
                        {!isView && (
                          <div className="form-group col-sm-5">
                            <label className="label-control w-100"></label>
                            {selectedEmployee != null ? (
                              <button className="btn btn-danger" onClick={() => setSelectedEmployee(null)}>
                                <span class="material-icons">autorenew</span>
                              </button>
                            ) : null}
                            <button className="btn btn-info" onClick={handleEmployeeSelectModal}>
                              {"Koordinator seç"}
                            </button>
                          </div>
                        )}
                      </>

                      <FormInput style={"col-sm-4"} label={"Müqavilə nömrəsi"} name={"contractNo"} value={contractJuridical?.contractNo} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Müqavilə tarixi"} name={"contractDate"} required={true} type={"date"} value={contractJuridical?.contractDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-4"}
                        label={"Təlim növü"}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdTrainingType");
                        }}
                        selectedValue={contractJuridical?.fkIdTrainingType}
                        isDisabled={isView}
                        options={trainingTypes.map((item) => ({ label: item.description, value: item.idTrainingType }))}
                      />
                      <FormInput style={"col-sm-4"} label={"Məbləğ"} name={"contractAmount"} type={"number"} required={true} value={contractJuridical?.contractAmount} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Endirim"} name={"discountAmount"} type={"number"} value={contractJuridical?.discountAmount} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Başlama tarixi"} name={"startDate"} type={"date"} required={true} value={contractJuridical?.startDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Bitmə tarixi"} name={"endDate"} type={"date"} required={true} value={contractJuridical?.endDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-8"} label={"Qeyd"} name={"note"} value={contractJuridical?.note} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "condition" ? "active" : "")}>
                    <div className="row">
                      <FormCheckbox label={"Müqavilənin dayandırılmasından aslı olmayaraq hüquqi şəxs üçün ödəniş hesablanır"}  style={"col-sm-12"} checked={contractJuridical?.condition01} name={"condition01"} onChange={() => setContractJuridical({ ...contractJuridical, condition01: contractJuridical?.condition01 == 1 ? 0 : 1 })} disabled={isView} />

                      <br />

                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-6"}
                        label={"Hesablanma tarixi"}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdContractCalculationDateType");
                          setSelectedCalculateDateType(calculateDateTypes.find((x) => x.idContractCalculationDateType == e.value));
                        }}
                        selectedValue={contractJuridical?.fkIdContractCalculationDateType}
                        isDisabled={isView}
                        options={calculateDateTypes.map((item) => ({ label: item.description, value: item.idContractCalculationDateType }))}
                      />
                      {selectedCalculateDateType?.requestParameter == 1 ? <FormInput style={"col-sm-6"} type={"number"} label={selectedCalculateDateType?.parameterTitle} name={"calculationDateValue"} value={contractJuridical?.calculationDateValue} onChange={handleInput} disabled={isView} /> : null}
                    </div>
                    <br /> <p className="color-red">{selectedCalculateDateType?.note}</p>
                  </div>
                </div>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "file" ? "active" : "")}>
                    {isView ? null : (
                      <div className="row">
                        <span className="btn-file pl-15">
                          <button type="submit" className="btn btn-fill btn-info ">
                            Fayl seç
                          </button>
                          <input type="file" onChange={onImageChange} accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.tif,.xls,.xlsx" />
                        </span>
                      </div>
                    )}
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Faylın adı</th>
                            <th>Faylın tipi</th>
                            <th>Faylın ölçüsü</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {contractJuridical?.attachments?.map((item, i) => (
                            <tr key={i}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item?.originalFileName}</td>
                              <td>{item?.fileType}</td>
                              <td></td>
                              <td className="td-actions text-right">
                                {isView ? null : (
                                  <button className="btn btn-danger" onClick={() => deleteExsistAttachment(item?.idAttachment)}>
                                    <i className="material-icons">delete</i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}

                          {attachs.map((item, i) => (
                            <tr key={i}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item?.name}</td>
                              <td>{item?.type}</td>
                              <td>{(item?.size / (1024 * 1024)).toFixed(2)} Mb</td>
                              <td className="td-actions text-right">
                                {isView ? null : (
                                  <button className="btn btn-danger" onClick={() => setAttachs(attachs.filter((x) => x != item))}>
                                    <i className="material-icons">delete</i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "status" ? "active" : "")}>
                    <Select
                      placeholder={"Seç"}
                      style={"col-sm-4"}
                      label={"Müqavilə statusu"}
                      changeSelectHandler={(e) => {
                        handleSelect(e, "fkIdContractStatus");
                      }}
                      selectedValue={contractJuridical?.fkIdContractStatus}
                      isDisabled={isView}
                      options={contractStatuses.map((item) => ({ label: item.description, value: item.idContractStatus }))}
                    />
                    <FormInput style={"col-sm-8"} label={t("contract.contractStatusNote")} name={"contractStatusNote"} value={contractJuridical?.contractStatusNote} onChange={handleInput} disabled={isView} />
                  </div>
                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateContractJuridical} />}
                  <Link to={`${alias}/contract/Juridical`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenJuridicalPersonSelectModal ? <JuridicalPersonSelectModal isOpen={isOpenJuridicalPersonSelectModal} handleJuridicalPersonSelectModal={handleJuridicalPersonSelectModal} setSelectedJuridicalPerson={setSelectedJuridical} /> : null}
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} /> : null}
    </div>
  );
}
